<template>
  <div class="class-content">
    <div class="__header">
      <h4>Study Materials</h4>
    </div>
    <div v-if="studyMaterials.length > 0" class="row">
      <div
        class="col-lg-3 col-md-4"
        v-for="(studyMaterial, studyMaterialIndex) in studyMaterials"
        :key="studyMaterialIndex"
      >
        <div class="pdf-card">
          <div class="pdf_image-wrap">
            <img src="../assets/study-material-img.jpg" />
          </div>
          <div class="pdf_body-wrap">
            <h6>{{ studyMaterial.file_original_name }}</h6>
            <p class="tag">{{ studyMaterial.subject_title }}</p>
            <a
              href="#"
              class="small-cta"
              @click.prevent="
                downloadFile(
                  studyMaterial.file,
                  studyMaterial.file_original_name
                )
              "
              ><i class="fas fa-download"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <p>Study Material is empty</p>
    </div>
    <div class="paging-wrapper">
      <v-pagination
        v-model="upcomingSchedulePage"
        :pages="upcomingScheduleTotalPages"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="upcomingScheduleChangeHandler"
        v-if="upcomingScheduleTotalClass > 0"
      />
    </div>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService.js";
import errorLog from "@/errorLog";
import "vue3-pagination/dist/vue3-pagination.css";
//import WebinarLoader from "@/components/loaders/WebinarLoader";
import VPagination from "vue3-pagination";

export default {
  name: "ClassTab",
  components: {
    //WebinarLoader,
    VPagination
  },
  data() {
    return {
      slug: this.$route.params.slug,
      studyMaterials: [],
      errorMessage: "No Study Material found "
    };
  },
  created() {
    this.getStudyMaterials();
    //this.downloadFile();
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    async getStudyMaterials() {
      //this.isMembershipLoader = true;
      //const data = { slug: this.slug };
      await ProgramService.getStudyMaterials({ slug: this.slug })
        .then(result => {
          //this.isMembershipLoader = false;
          this.studyMaterials = result.data.study_material;
        })
        .catch(error => {
          this.isMembershipLoader = false;
          errorLog.log(error);
        });
    },
    upcomingScheduleChangeHandler(selectedPage) {
      this.upcomingSchedulePage = selectedPage;
      this.getSchedules(this.upcomingSchedulePage);
    },

    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
    downloadFile: function(file, filename) {
      ProgramService.downloadFile(file)
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/study-material.scss";
</style>
